<template>
  <div class="identify-two-factors-container">
    <PanelHeader title="شناسایی دو عاملی" />
    <div class="content-wrapper">
      <div class="content-card">
        <div class="information">
          به منظور افزایش سطح ایمنی حساب کاربری خود را میتوانید احراز هویت دو
          مرحله ای را فعال کنید. با فعال کردن این قابلیت حساب کاربری شما در
          برابر حملات هکرها, فیشینگ و سوِء استفاده افراد سودجو ایمن خواهد بود.
        </div>
        <div class="tab-form-container">
          <div class="header" :class="[left ? 'left' : '']">
            <div class="sms-identification" @click="toggleRightSide">
              شناسایی با ارسال پیامک
            </div>
            <div class="two-step-verification" @click="toggleLeftSide">
              تایید دو مرحله گوگل
            </div>
          </div>
          <div class="content" v-if="!left">
            <div class="content-information-right">
              با فعالسازی این گزینه برای هر بار ورود به شماره موبایلی که ثبت
              کرده اید کد پنج رقمی ارسال میشود و آن کد را باید موقع ورود درج
              کنید.
            </div>
            <div class="button">
              <ActiveButton />
            </div>
          </div>
          <div v-if="left" class="content-information-left">
            <div class="first-row">
              جهت فعال سازی این قابلیت، مراحل زیر را دنبال کنید:<br /><br />
              1. آخرین نسخه Google Authenticator را از گوگل پلی یا اپل استور
              دریافت نمایید.<br />
              2. پس از نصب و اجرای برنامه Google Authenticator از طریق یکی از
              روش های زیر، کلید را به برنامه اضافه نمایید. - Scan a barcode
              (اسکن بارکد): این گزینه را انتخاب کرده و بارکد زیر را اسکن نمایید.
            </div>
            <div class="second-row">
              <div class="right-text">
                <div class="one">
                  (provided a Enter) با استفاده از کلید: این گزینه را انتخاب
                  کرده و کد زیر را به دقت وارد نمایید.
                </div>
                <div class="code">
                  VY2BQWKIHKXOYSRP
                </div>
                <div class="two">
                  3. کد دریافتی(عدد 6 رقمی) را در کادر زیر وارد نموده و دکمه
                  فعال سازی را کلیک نمایید.
                </div>
                <div class="code-input">
                  <GrayInput placeholder="عدد شش رقمی" />
                </div>
                <div class="button">
                  <ActiveButton />
                </div>
              </div>
              <div class="barcode-image">
                <img src="../assets/images/barcode.svg" alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/PanelHeader";
import ActiveButton from "@/components/Button/ActiveButton";
import GrayInput from "@/components/input/GrayInput";

export default {
  name: "IdentifyTwoFactors",
  components: { GrayInput, ActiveButton, PanelHeader },
  data() {
    return {
      left: false
    };
  },
  methods: {
    toggleRightSide() {
      this.left = false;
      // console.log("right");
    },
    toggleLeftSide() {
      this.left = true;
      // console.log("left");
    }
  }
};
</script>

<style scoped>
.identify-two-factors-container {
  width: calc(100% - 250px);
  margin-right: auto;
  height: calc(100vh - 100px);
}

.information {
  margin-bottom: 30px;
}

.content-wrapper {
  padding: 0 40px;
  margin-top: 20px;
}

.content-card {
  max-width: 1111px;
  width: 100%;
  min-height: 497px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.tab-form-container {
  width: 100%;
  max-width: 711px;
  min-height: 289px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  align-self: center;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.header {
  display: flex;
  padding: 0 0 10px 0;
  position: relative;
}

.header::after {
  content: "";
  display: inline-block;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: rgba(7, 13, 89, 0.2);
}

.header::before {
  transition: right 0.2s linear;
  content: "";
  display: block;
  height: 3px;
  width: 50%;
  position: absolute;
  bottom: -1px;
  border-radius: 5px;
  background: #1f3c88;
}

.header.left::before {
  right: 50%;
  /*background-color: #039000;*/
}

.header::before .left {
  content: "";
  display: block;
  height: 3px;
  width: 50%;
  background: rgba(7, 13, 89, 0.2);
  position: absolute;
  bottom: -1px;
  left: 0;
  border-radius: 5px;
  background: #1f3c88;
}

.sms-identification {
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.two-step-verification {
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.content-information-right {
  font-weight: normal;
  font-size: 14px;
  margin-top: 20px;
}

.button {
  margin: 125px auto 0 auto;
  width: 150px;
  /*align-self: center;*/
}

.first-row {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
}

.second-row {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}

.one {
  font-size: 14px;
}

.code {
  font-size: 22px;
  color: #1f3c88;
  font-weight: 500;
  float: left;
}

.two {
  font-size: 14px;
  margin-top: 60px;
}

.barcode-image {
  width: 100%;
  /*background: url("../assets/images/barcode.svg");*/
  /*width: 20px;*/
}

.right-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.code-input {
  float: left;
  margin-top: 25px;
}

@media (max-width: 1000px) {
  .identify-two-factors-container {
    width: 100%;
    height: 100vh;
  }
}

@media (max-width: 560px) {
  .content-wrapper {
    padding: 0 10px;
  }

  .information {
    font-size: 14px;
  }
}
</style>
