<template>
  <div class="button-container">
    <div class="title">
      فعال سازی
    </div>
  </div>
</template>

<script>
export default {
  name: "ActiveButton"
};
</script>

<style scoped>
.button-container {
  min-width: 150px;
  background: #1f3c88;
  border-radius: 5px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
</style>
